import React, { useContext, useEffect, useState } from 'react'
import MainContent from '../../Components/UserComponents/MainContent/MainContent'
import BlogSection from '../../Components/UserComponents/BlogSection/BlogSection'
import SocialSection from '../../Components/UserComponents/SocialAdvertise/SocialSection'
import { PostsContext } from '../../Contexts/PostContext'
import imageCompression from 'browser-image-compression';
import { usePostCategory } from '../../Contexts/PostCategoryContext'
import LoadingPage from './LoadingPage/LoadingPage'
import axiosInstance from '../../axiosConfig'; // Import the Axios instance
import { useParams } from 'react-router-dom'


const LandingPage = ({ mainContentMargin }) => {

    const { posts, setPosts } = useContext(PostsContext);
    const { postCategory } = usePostCategory();
    
    const { category } = useParams()


    const apiUrl = process.env.REACT_APP_RENDER_EXTERNAL_URL;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const fetchPosts = async () => {
            setIsLoading(true);
            let fetchedPosts = null; // Change const to let
            let response; // Declare response variable
            try {
                if (postCategory) {
                    console.log(postCategory);
                    response = await axiosInstance.get(`/api/blogpost/categories/category`, {
                        params: { category: postCategory }
                    }); 
                    fetchedPosts = await response.data;
                } else {
                    // response = await fetch(`${apiUrl}/api/blogpost/`);
                    response = await axiosInstance.get(`/api/blogpost/`);
                    fetchedPosts = await response.data;
                }
                // const response = await fetch('/api/blogpost/');
                // const fetchedPosts = await response.json();

                console.log(fetchedPosts, response);

                if (response.status === 200) {
                    console.log('Posts fetched successfully:', fetchedPosts);
                    const posts = fetchedPosts.map(post => ({ 
                        ...post, 
                        showContent: stripHtmlTags(post.content) 
                    }));

                    setPosts(posts);
                    setIsLoading(false);

                } else {
                    console.error('Error fetching posts:');
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('An error occurred while fetching the posts:', error);
            }
        };

        fetchPosts();


        const filteredPosts = category
            ? posts.filter(post => post.category === category)
            : posts;

        console.log(category, filteredPosts);

        setPosts(filteredPosts);

    }, [setPosts, category]);


    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    return (
        <div className='main-div'>
            {isLoading ? (
                <LoadingPage />
            ) : (
                <div>
                    <MainContent mainContentMargin={mainContentMargin} />
                    <BlogSection />
                    <SocialSection />
                </div>
            )
            }
        </div>

    )
}

export default LandingPage;