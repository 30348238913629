import React, { useState } from 'react';
import './Footer.css';
import { Col, Nav, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { usePostCategory } from '../../../Contexts/PostCategoryContext';
import { Avatar } from '@mui/material';
import Logo from '../../../Assets/Images/sakshi logo B.png';
import Logo2 from '../../../Assets/Images/sakshi logo B croped.png';

const Footer = () => {

  const { setPostCategory } = usePostCategory();
  const [email, setEmail] = useState('');

  const handleCategoryClick = async (category, path) => {
    console.log(category);
    setPostCategory(category);

    navigate(path);

  };

  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    if (email) {
      window.location.href = `mailto:example@example.com?subject=Subscribe&body=Please subscribe me to the newsletter. My email is ${email}`;
    } else {
      alert('Please enter a valid email address.');
    }
  };


  return (
    <footer className="footer">
      <div className="footer-content row px-3 mt-3">
        <div className="logo-section col-12 col-md-2">
          <img style={{ width: '250px' }} src={Logo2} className='d-none d-md-block' alt="" />
          <img style={{ width: '250px' }} src={Logo2} className='d-block d-md-none mx-auto pb-4' alt="" />
          <p className="d-none d-md-block mt-3">
            Sakshi is a Magazine where you can find the best Articles, Studies, Discussions,
            Stories, Poems, and much more. We have a team of professional writers who
            write amazing Writings for you.
          </p>
        </div>
        {/* <div className="about d-none d-md-block">
          <h4>About & Contact Us</h4>
          <a href="/">About Us</a>
          <a href="/">Contact Us</a>
        </div> */}
        <div className="menue d-none d-md-block">
          <h4>Navigation Menue</h4>
          <Nav.Link onClick={() => handleCategoryClick("", "/")}>Home</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Cover", "/category/cover")}>Cover</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Article", "/category/article")}>Article</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Current Affair", "/category/current-affair")}>Current Affair</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Fiction", "/category/fiction")}>Fiction</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Studies", "/category/studies")}>Studies</Nav.Link>

        </div>
        {/* <div className="menue d-none d-md-block">
          <h4>Navigation Menue</h4>
          <Nav.Link onClick={() => handleCategoryClick("", "/")}>Home</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Cover", "/category/cover")}>Cover</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Article", "/category/article")}>Article</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Current Affair", "/category/current-affair")}>Current Affair</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Fiction", "/category/fiction")}>Fiction</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Studies", "/category/studies")}>Studies</Nav.Link>

        </div> */}
        <div className="social newsletter m-0 px-3">
          <h4>Get Updates</h4>
          <div className='d-flex mail-box mb-3 ms-0'>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleSubscribeClick}>Subscribe</button>
          </div>
          <div className='m-0 m-0 d-flex justify-content-center pe-2'>
            <div className='m-0'>
              <a href='https://www.youtube.com/@SakshiMonthly' target='blank' >
                <Avatar className='mx-2' sx={{ bgcolor: '#fff' }}>
                  <i class="fa-brands fa-youtube fa-lg" style={{ color: '#3b6ab2' }}></i>
                </Avatar>
              </a>
            </div>
            <div className='m-0'>
              <a href='https://www.facebook.com/profile.php?id=100071561641880' target='blank'>
                <Avatar className='mx-2' sx={{ bgcolor: '#fff' }}>
                  <i class="fa-brands fa-facebook fa-lg" style={{ color: '#3b6ab2' }}></i>
                </Avatar>
              </a>
            </div>
            <div className='m-0'>
              <a href='https://www.instagram.com/sakshimagazine_/' target='blank' >
                <Avatar className='mx-2' sx={{ bgcolor: '#fff' }}>
                  <i class="fa-brands fa-instagram fa-lg" style={{ color: '#3b6ab2' }}></i>
                </Avatar>
              </a>
            </div>
            <div className='m-0'>
              <a href='https://whatsapp.com/channel/0029Vagvcdl3WHTTzuJHgv1l' target='blank' >
                <Avatar className='ms-2 me-3' sx={{ bgcolor: '#fff' }}>
                  <i class="fa-brands fa-whatsapp fa-lg" style={{ color: '#3b6ab2' }}></i>
                </Avatar>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom row mt-4 d-none d-md-block">
        <Row className='mx-auto'>
          <Col md={6} className='d-flex justify-content-start'>
            <span>©2024 Sakshi Magazine. All rights reserved.</span>
          </Col>
          <Col md={6} className='d-flex justify-content-end'>
            <div className="ddb">
              <p>Designed and Developed by <a href="https://www.instagram.com/tropicks___/" target='blank'>TROPICKS</a></p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-bottom row mt-4 d-block d-md-none">
        <Row>
          <Col md={12} className='text-center'>
            <span>©2024 Sakshi Magazine. All rights reserved.</span>
            <div className="ddb">
              <p>Designed and Developed by <a href="https://www.instagram.com/tropicks___/" target='blank'>TROPICKS</a></p>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
