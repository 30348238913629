import React, { useContext, useEffect, useState } from 'react';
import './BlogSection.css';
import Posts from '../../../Assets/Posts/Posts';
import { usePostCategory } from '../../../Contexts/PostCategoryContext';
import Pagination from '@mui/material/Pagination';
import { PostsContext } from '../../../Contexts/PostContext';
import { Col, Row } from 'react-bootstrap';



const BlogSection = () => {

  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 12;

  const { postCategory } = usePostCategory()
  const { posts, setPosts } = useContext(PostsContext);
  const category = postCategory.toUpperCase(postCategory);

  // useEffect(() => {
  //   const fetchBlogPost = async () => {
  //     try {

  //       let response;
  //       let posts = null;

  //       if (postCategory) {
  //         console.log(postCategory);
  //         response = await fetch(`/api/blogpost/categories/category?category=${postCategory}`);
  //         posts = await response.json();
  //       } else {
  //         response = await fetch('/api/blogpost/');
  //         posts = await response.json();
  //       }


  //       if (response.ok && posts) { // Check if response is ok and posts is not null
  //         const postsWithImages = await Promise.all(
  //           posts.map(async (post) => {
  //             const imageUrl = await fetchImage(post.image);
  //             return { ...post, imageUrl, showContent: stripHtmlTags(post.content) };
  //           })
  //         );
  //         setBlogPosts(postsWithImages);
  //       } else {
  //         console.error("There was an error fetching the blog posts!");
  //       }
  //     } catch (error) {
  //       console.error("There was an error fetching the blog posts!", error);
  //     }
  //   };

  //   const fetchImage = async (imageId) => {
  //     try {
  //       const response = await fetch(`/api/image/${imageId}`);
  //       if (response.ok) {
  //         const blob = await response.blob();
  //         return URL.createObjectURL(blob);
  //       } else {
  //         console.error('There was an error fetching the image!');
  //         return null;
  //       }
  //     } catch (error) {
  //       console.error('There was an error fetching the image!', error);
  //       return null;
  //     }
  //   };

  //   fetchBlogPost();
  // }, [postCategory, posts]);

  console.log({ blogPosts });

  // Calculate the indexes for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <section className="blog-section">
      {postCategory ? <h2 className='cat-title text-left ms-5'>{category}</h2> : <h2 className='cat-title text-left ms-5'>RECENTS</h2>}

      <div className="blog-posts mt-3">
        <Row>
          {currentPosts && currentPosts.map((post, index) => (
            <Col md={3}>
              <Posts key={index} post={post} />
            </Col>
          ))}
        </Row>
      </div>
      <div className="pagination mt-3 mb-5 d-flex justify-content-center">
        <Pagination
          count={Math.ceil(posts.length / postsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </section>
  );
};

export default BlogSection;
