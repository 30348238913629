import React from 'react'
import './PostArticleArea.css'
import { Helmet } from 'react-helmet';
import Image from '../../../Assets/Images/articles.jpg'

function PostArticleArea({ post }) {
    console.log(post);
    const currentUrl = window.location.href; // Get the current page URL

    // Construct the WhatsApp message
    const message =
`Check out this post: *${post.title}*

${post.showContent.substring(0, 250)}... 

Read more at: *${currentUrl}*

- ${post.author}
    `;

    // Encode the message for the WhatsApp URL
    const encodedMessage = encodeURIComponent(message);

    const shareUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;

    return (
        <div className='post-article-area'>
            <div className="post-content">
                <div className="article-content d-sm-block d-md-none px-3" dangerouslySetInnerHTML={{ __html: post.content }} ></div>
                <div className="article-content d-none d-md-block" dangerouslySetInnerHTML={{ __html: post.content }} ></div>
                <a href={shareUrl} data-action="share/whatsapp/share" target="_blank" type="button" className="me-sm-5 mb-sm-5 shadow btn btn-danger btn-floating btn-lg" id="btn-back-to-top">
                    <i class="fa-solid fa-share"></i>
                </a>
            </div>
        </div>
    )
}

export default PostArticleArea